import React from 'react'
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss'
import BgVideo from '../images/video.png'
import Play from '../images/icons/play.svg'

import {
  imgPlay,
  videoImg
} from '../components/styles/Dicas.styles'

class App extends React.Component {

  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal () {
    this.setState({isOpen: true})
  }

  render () {
    return (
      <React.Fragment>
        <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='L61p2uyiMSo' onClose={() => this.setState({isOpen: false})} />
        <div
          css={videoImg}
          style={{ 
            backgroundImage: `url(${BgVideo})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
          <div css={imgPlay} onClick={this.openModal} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <img src={Play} style={{ width: 108 }} />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default App