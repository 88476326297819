import * as React from 'react'
import DepoimentosItens from './DepoimentosItens'
import Button from '../components/StartNowBtnGray'
import { Link } from 'gatsby'

import {
  styleRecursosCompletos,
  container,
  content,
  btn,
  mt52
} from '../components/styles/RecursosCompletos.styles'


const RecursosCompletos = () => {
  return (
    <div css={[styleRecursosCompletos, content]}>
      <h2 style={{ color: '#EAEAEA' }}>DEPOIMENTOS</h2>
      <div css={[container, mt52]}>
        <DepoimentosItens />
        <a href='https://app.redacaosolidaria.org' css={btn}><Button>COMECE AGORA MESMO</Button></a>
      </div>
    </div>
  )
}

export default RecursosCompletos