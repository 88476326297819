import * as React from 'react'
import Button from '../components/StartNowBtn'
import Img1 from '../images/dificuldades-com-a-redacao.png' 

import {
  column,
  columns,
  container,
  header,
  content,
  mobile,
  desktop
} from '../components/styles/Header.styles'

const Header = () => {
  return (
    <div css={[header, content]}>
      <div css={container}>
        <div css={columns}>
          <div css={column}>
            <h1 style={{ marginBottom: 18 }}>DIFICULDADES<br /> COM REDAÇÃO?<br /> NÓS TE AJUDAMOS!</h1>
            <p>A plataforma do Redação Solidária disponibiliza avaliação e revisão, feita por professores qualificados, <span>de forma gratuita</span>.</p>
            <a href='https://app.redacaosolidaria.org' css={desktop}><Button>COMECE AGORA MESMO</Button></a>
          </div>
          <div css={column}>
            <img src={Img1} alt='' />
            <a href='https://app.redacaosolidaria.org' css={mobile} style={{ margin: '32px 0' }}><Button>COMECE AGORA MESMO</Button></a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header