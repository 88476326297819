import * as React from 'react'

import {
  btn
} from '../components/styles/EnvieSuaRedacaoBtn.styles'

const EnvieSuaRedacaoBtn = ({ children }) => (
  <button css={btn}>{ children }</button>
)

export default EnvieSuaRedacaoBtn
