import * as React from 'react'
import Button from '../components/StartNowBtn'
import Img from '../images/domine-a-redacao.png'
import { Link } from 'gatsby'

import {
  column,
  columns,
  container,
  domineARedacao,
  content,
  contentPosition,
  desktop,
  mobile
} from '../components/styles/Header.styles'

const DomineARedacao = () => {
  return (
    <div css={[domineARedacao, content]}>
      <div css={container}>
        <div css={columns}>
          <div css={[column, desktop]}>
            <img src={Img} alt='' />
          </div>
          <div css={column}>
            <div css={contentPosition}>
              <h2 style={{ marginBottom: 18 }}>DOMINE A REDAÇÃO <br />E CONQUISTE SUA VAGA</h2>
              <p><span> Alunos de todo o Brasil:</span> Prepare-se para o ENEM e vestibulares nacionais do jeito certo. Temos um time de professores dedicados para te ajudar a destravar na escrita!</p>
              <img css={mobile} style={{ marginTop: 16 }} src={Img} alt='' />
              <a href='https://app.redacaosolidaria.org' css={desktop}><Button>COMECE AGORA MESMO</Button></a>
              <a href='https://app.redacaosolidaria.org' css={mobile} style={{ margin: '32px 0' }}><Button>COMECE AGORA MESMO</Button></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DomineARedacao