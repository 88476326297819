import * as React from 'react'
import { globalStyles } from '../components/layout.styles'
import { Global } from '@emotion/react'
import NavBar from '../components/NavBar'
import Header from '../components/Header'
import * as Scroll from 'react-scroll'
import DomineARedacao from '../components/DomineARedacao'
import PorqueEstudarRedacao from '../components/CTA'
import ImportanciaDaRedacao from '../components/ImportanciaDaRedacao'
import RecursosCompletos from '../components/RecursosCompletos'
import ComoFunciona from '../components/ComoFuncioaARedacaoSolidaria'
import PorqueEstudar from '../components/CtaPorquEstudar'
import ProfessorReima from '../components/ProfessorReima'
import Dicas from '../components/Dicas'
import ComeceSuaJornada from '../components/ComeceSuaJornada'
//import QueroSerUmCorretor from '../components/QueroSerUmCorretor'
import Depoimentos from '../components/Depoimentos'
import Footer from '../components/Footer'
import SEO from "../components/Seo"
import BaixarEbook from '../components/BaixarEbook'

const IndexPage = () => {
  return (
    <div>
      <Global styles={globalStyles} />
      <SEO title="Redação Solidária | Rafael Riemma" />
      <NavBar />
      <Scroll.Element
        id='sobre'
      >
        <Header />
      </Scroll.Element>
      <DomineARedacao />
      <PorqueEstudarRedacao />
      <BaixarEbook />
      {false && <ImportanciaDaRedacao />}
      <Scroll.Element>
        <div id='beneficios' style={{ display: 'block', position: 'relative', top: '-120px', visibility: 'hidden' }}></div>
        <RecursosCompletos />
      </Scroll.Element>
      <ComoFunciona />
      <PorqueEstudar />
      <Scroll.Element>
        <div id='riema' style={{ display: 'block', position: 'relative', top: '-120px', visibility: 'hidden' }}></div>
        <ProfessorReima />
      </Scroll.Element>
      <Scroll.Element>
        <div id='dicas' style={{ display: 'block', position: 'relative', top: '-120px', visibility: 'hidden' }}></div>
        <Dicas />
      </Scroll.Element>
      <ComeceSuaJornada />
      {/* <QueroSerUmCorretor /> */}
      <Scroll.Element>
        <div id='depoimentos' style={{ display: 'block', position: 'relative', top: '-120px', visibility: 'hidden' }}></div>
        <Depoimentos />
      </Scroll.Element>
      <Footer />
    </div>
  )
}

export default IndexPage
