import React, { Fragment } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Video from '../components/Video5DicasParaMelhorarOSeuRepertorio'
import Video2 from '../components/VideosExplicadoModeloDeRedacao'
import Video3 from '../components/VideoAtravesDeXPorMeioDe'
import Video4 from '../components/VideoEmVezDeXAoInvesDe'
import Video5 from '../components/VideoMasxMais' 
import Video6 from '../components/VideoOndeXAonde' 
import Video7 from '../components/VideoUsoDosPorques' 

class VideosCarousel extends React.Component {
  render() {
    var settings = {
      className: "slider variable-width",
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1, 
            slidesToScroll: 1,
            arrows: false,
            dots: true
          }
        }
      ]
    }
    
    return (
      <Fragment>
        <Slider {...settings}>
          <div>
            <Video />
          </div>
          <div>
            <Video2 />
          </div>
          <div>
            <Video3 />
          </div>
          <div>
            <Video4 />
          </div>
          <div>
            <Video5 />
          </div>
          <div>
            <Video6 />
          </div>
          <div>
            <Video7 />
          </div>
        </Slider>
      </Fragment>
    )
  }
}

export default VideosCarousel