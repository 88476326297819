import * as React from 'react'
import BgForm from '../images/bg-form.png'
import BgMobile from '../images/bg-form-mobile.png'
import Button from '../components/StartNowBtn'

import {
  styleDicas,
  content,
  container,
  columns,
  header,
  column,
  label,
  formulario,
  mobile,
  desktop,
  imgFormDesktop
} from '../components/styles/ComeceSuaJornada.styles'

const ComoFunciona = () => {
  return (
    <div css={[styleDicas, content]}>
      <div
        css={desktop}
        style={{ 
          backgroundImage: `url(${BgForm})`,
          backgroundRepeat: 'no-repeat',
          height: 367,
          width: 1306,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <div css={header}>
          <h2>DÚVIDAS, SUGESTÕES, COMENTÁRIOS?</h2>
        </div>
      </div>
      <div css={mobile}>
        <div css={header}>
          <h2>DÚVIDAS, SUGESTÕES, COMENTÁRIOS?</h2>
        </div>
        <img src={BgMobile} />
      </div>
      <div css={container}>
        <div css={columns}>
          <div css={column}>
            <div css={imgFormDesktop}>
              <div css={formulario}>
                <form style={{ margin: '0 auto' }}>
                  <div css={label}>
                    <input placeholder='Nome completo' type="text" name="name" id="name" required />
                  </div>
                  <div css={label}>
                    <input placeholder='Seu melhor e-mail' type="text" name="email" id="email" required />
                  </div>
                  <div css={label}>
                    <input placeholder='Telefone' type="text" name="phone" id="phone" required />
                  </div>
                  <div css={label}>
                    <textarea placeholder='Mensagem' name="message" id="message" rows="1" />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: 115 }}><Button>PARTICIPE AGORA</Button></div>
                </form> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComoFunciona