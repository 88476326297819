import * as React from 'react'
import Video from '../components/Video2'
import Carousel from './VideosCarousel'

import {
  styleDicas,
  content,
  container,
  columns
} from '../components/styles/Dicas.styles'

const Dicas = () => {
  return (
    <div css={[styleDicas, content]}>
      <h2>DICAS</h2>
      <p>Assista aos vídeos e confira dicas rápidas e infalíveis para melhorar a sua nota na redação!</p>
      <div css={container}>
        {false && <div css={columns}>
          <Video />
        </div>}
        <div>
          <Carousel />
        </div>
      </div>
    </div>
  )
}

export default Dicas