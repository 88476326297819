import * as React from 'react'
import Conteudos from '../images/icons/conteudos.png'
import Atividades from '../images/icons/atividades.png'
import Materiais from '../images/icons/materiais.png'
import Plataforma from '../images/icons/plataforma.png'
import Button from '../components/EnvieSuaRedacaoBtn'

import {
  styleRecursosCompletos,
  container,
  content,
  columns,
  column,
  boxContent
} from '../components/styles/RecursosCompletos.styles'

const RecursosCompletos = () => {
  return (
    <div css={[styleRecursosCompletos, content]}>
      <h2>RECURSOS COMPLETOS TOTALMENTE GRATUITOS</h2>
      <p>Preparamos uma ferramenta completa para auxiliar você na construção de pensamento crítico e, assim, destravar o seu potencial.</p>
      <div css={container}>
        <div css={columns} style={{ margin: '0 auto' }}>
          <div css={column}>
            <div css={boxContent}>
              <img src={Conteudos} alt='' />
              <h3>conteúdos</h3>
              <h4>Conteúdos didáticos sobre redação através de aulas e artigos</h4>
            </div>
          </div>
          <div css={column}>
            <div css={boxContent}>
              <img src={Atividades} alt='' />
              <h3>atividades</h3>
              <h4>Exercícios e questionários totalmente dinâmicos</h4>
            </div>
          </div>
          <div css={column}>
            <div css={boxContent}>
              <img src={Materiais} alt='' />
              <h3>materiais</h3>
              <h4>Download de material de apoio para impressão</h4>
            </div>
          </div>
          <div css={column}>
            <div css={boxContent}>
              <img src={Plataforma} alt='' />
              <h3>plataforma</h3>
              <h4>Ferramenta avançada de revisão de redação</h4>
            </div>
          </div>
        </div>
        <a href='https://app.redacaosolidaria.org' style={{ display: 'flex', justifyContent: 'center', marginTop: 32 }}><Button>ENVIE SUA REDAÇÃO AINDA HOJE</Button></a>
      </div>
    </div>
  )
}

export default RecursosCompletos