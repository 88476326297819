import { css } from "@emotion/react"

export const styleDicas = css`
  background-color: #EAEAEA;
  font-family: 'Antonio', sans-serif;
  padding-top: 62px;
  padding-bottom: 52px;
`
export const container = css`
  width: 100%;
  margin: 0 auto;
  max-width: 1240px;
`

export const columns = css`
  display: flex;
	flex-flow: row wrap;
	justify-content: center;
	/* margin: 5px 0; */
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
    flex-direction: column;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const column = css`
  flex: 1;
	margin: 2px;
	padding: 10px;
	&:first-child { margin-left: 0; }
	&:last-child { margin-right: 0; }
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const content = css`
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 55px;
    line-height: 79px;
    display: flex;
    align-items: center;
    letter-spacing: -0.05em;
    font-weight: normal;
    margin: 0;
    margin-bottom: 10px;
    color: #1E1E1E;
  }
  p {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #1E1E1E;
    max-width: 764px;
    width: 100%;
    margin: 0;
    margin-bottom: 32px;
    span {
      font-weight: bold;
    }
  }
  @media (max-width: 480px) {
    h2 {
      font-size: 45px;
      line-height: 54px;
      margin-bottom: 20px;
    }
  }
`

export const imgPlay = css`
  img {
    transition: transform .7s ease-in-out;
    cursor: pointer;
    :hover {
      transform: rotate(360deg);
    }
  }
`

export const videoImg = css`
  height: 208px;
  width: 370px;
  margin: auto;
  @media (max-width: 480px) {
    max-width: 328px;
    width: 100%;
    height: 184px;
    margin: auto;
    background-repeat: no-repeat;
  }
`

export const videoCarousel = css`
  width: 340px;
  height: 190px;
  margin: auto;
  @media (max-width: 480px) {
    max-width: 328px;
    width: 100%;
    height: 184px;
    margin: auto;
    background-repeat: no-repeat;
  }
`

export const carouselContent = css`
  font-family: 'Poppins', sans-serif;
  /* max-width: 320px;
  width: 100%; */
  margin: auto;
  /* border-left: 2px solid #1E1E1E;
  border-right: 2px solid #1E1E1E; */
  h3 {
    font-weight: bold;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #1E1E1E;
    margin-bottom: 8px;
    margin-top: 20px;
    height: 66px;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #1E1E1E;
  }
  img {
    width: 90%;
  }
`