import * as React from 'react'

import {
  btnStartNowGray
} from '../components/styles/ButtonGray.styles'

const StartNowBtnGray = ({ children }) => (
  <button css={btnStartNowGray}>{ children }</button>
)

export default StartNowBtnGray
