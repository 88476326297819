import * as React from 'react'
import Button from '../components/EnvieSuaRedacaoBtn'

import { 
  ctaBox,
  ctaPrqEstudar
} from '../components/styles/Cta.styles'

const CtaPorqueEstudar = () => {
  return (
    <div css={ctaPrqEstudar}>
      <div css={ctaBox}>
        <h2 style={{ color: '#FFFAA9' }}>MAS É DE GRAÇA MESMO?</h2>
        <p style={{ color: '#FFFAA9' }}><span>Sim! Nossa plataforma é 100% gratuita</span>. Você só precisa fazer a sua melhor redação (sempre usando caneta, porque não corrigimos redações a lápis!) e aguardar a correção. Você será notificado por e-mail, quando tudo estiver prontinho. Nada além disso! Combinado? Ah, capriche na letra! :)</p>
        <a href='https://app.redacaosolidaria.org'><Button>QUERO ENVIAR MINHA REDAÇÃO</Button></a>
      </div>
    </div>
  )
}

export default CtaPorqueEstudar