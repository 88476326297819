import React, { useState } from 'react';
import ItemsCarousel from 'react-items-carousel'
import ArrowL from '../images/arrow-white-l.svg'
import ArrowR from '../images/arrow-white-r.svg'
import Fidel from '../images/depoimentos/fidel.png'
import Lucca from '../images/depoimentos/lucca.png'
import Igor from '../images/depoimentos/igor.png'
import Miguel from '../images/depoimentos/miguel.png'
import Liviana from '../images/depoimentos/liviana.png'
import LeticiaPrince from '../images/depoimentos/leticia.png'
import LeticiaDantas from '../images/depoimentos/leticia-dantas.png'
import Samira from '../images/depoimentos/samira.png'
import AnaLaura from '../images/depoimentos/ana.png'
import Noemi from '../images/depoimentos/noemi.png'

import {
  columns,
  column,
  depoimentoItens,
  borderLine,
  arrowPosition,
  imgItem
} from '../components/styles/RecursosCompletos.styles'

export default () => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 20;
  return (
    <div style={{ padding: `0 ${chevronWidth}px` }}>
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={1}
        leftChevron={<button css={arrowPosition} style={{ border: 'none', background: 'transparent' }}><img src={ArrowL} style={{ width: 52, cursor: 'pointer' }} /></button>}
        rightChevron={<button css={arrowPosition} style={{ border: 'none', background: 'transparent' }}><img src={ArrowR} style={{ width: 52, cursor: 'pointer' }} /></button>}
        infiniteLoop
        chevronWidth={chevronWidth}
      >
        <div>
          <div css={[columns, depoimentoItens]}>
            <div css={column}>
              <div css={imgItem}>
                <img src={Fidel} alt='/' />
              </div>
            </div>
            <div css={borderLine} />
            <div css={column}>
              <h3>Fidel Morais / 900 Pontos</h3>
              <p>O Redação Solidária foi um ponto decisivo para a minha aprovação. O professor Riemma possibilitou, de forma gratuita, o meu acesso a um mundo desmitificado de redação, fato que, certamente, foi impresncindível para o meu sucesso como pré-vestibulando.</p>
            </div>
          </div>
        </div>
        <div>
          <div css={[columns, depoimentoItens]}>
            <div css={column}>
              <div css={imgItem}>
                <img src={Lucca} alt='/' />
              </div>
            </div>
            <div css={borderLine} />
            <div css={column}>
              <h3>Lucca Michels / 980 Pontos</h3>
              <p>As orientações do Prof. Riemma e da plataforma foram ingredientes essenciais para que eu alcançasse 980 na redação do ENEM. Eles trabalham para que o candidato seja eficiente no dia da prova e obtenha o domínio completo do que os examinadores buscam do vestibulando.</p>
            </div>
          </div>
        </div>
        <div>
          <div css={[columns, depoimentoItens]}>
            <div css={column}>
              <div css={imgItem}>
                <img src={Igor} alt='/' />
              </div>
            </div>
            <div css={borderLine} />
            <div css={column}>
              <h3>Igor Torres / 920 Pontos</h3>
              <p>Gratidão: palavra que define a minha relação com o Prof. Riemma e a equipe do Redação Solidária. Com muito trabalho, carinho, esforço e, o mais importante, competência dos profissionais envolvidos, fui capaz de conquistar 920 na redação do prova de 2020.</p>
            </div>
          </div>
        </div>
        <div>
          <div css={[columns, depoimentoItens]}>
            <div css={column}>
              <div css={imgItem}>
                <img src={Miguel} alt='/' />
              </div>
            </div>
            <div css={borderLine} />
            <div css={column}>
              <h3>Miguel Paula / 980 Pontos</h3>
              <p>Conquistei mais uma vez 980 na redação do ENEM e hoje, aguardo uma vaga para o curso dos meus sonhos na lista de espera (4º lugar) e também o SISU 2021.2. Agradeço ao Riemma pela iniciativa do projeto Redação Solidária – um projeto magnífico em prol da redução das desigualdades educacionais de nosso país.</p>
            </div>
          </div>
        </div>
        <div>
          <div css={[columns, depoimentoItens]}>
            <div css={column}>
              <div css={imgItem}>
                <img src={Liviana} alt='/' />
              </div>
            </div>
            <div css={borderLine} />
            <div css={column}>
              <h3>Liviana Gomes / 960 Pontos</h3>
              <p>O Redação Solidária me ajudou muito a evoluir! No ano passado eu tirei 740 e nesse ano eu alcancei os 960. Por mais que eu tenha me esforçado, houve muitas contribuições nessa nota e o Prof. Riemma, com o projeto, foi uma delas, por isso, obrigada de coração.</p>
            </div>
          </div>
        </div>
        <div>
          <div css={[columns, depoimentoItens]}>
            <div css={column}>
              <div css={imgItem}>
                <img src={LeticiaPrince} alt='/' />
              </div>
            </div>
            <div css={borderLine} />
            <div css={column}>
              <h3>Letícia Prince / 1000 Pontos</h3>
              <p>Em três meses o modelo me ajudou a entender e a reconhecer as estruturas fundamentais na dissertação, com isso atingi a nota 1000, seguindo a correção comentada dos corretores qualificados. Obrigada pela atenção e compromisso. O mérito é nosso, feliz em ter você na minha história.</p>
            </div>
          </div>
        </div>
        <div>
          <div css={[columns, depoimentoItens]}>
            <div css={column}>
              <div css={imgItem}>
                <img src={LeticiaDantas} alt='/' />
              </div>
            </div>
            <div css={borderLine} />
            <div css={column}>
              <h3>Letícia Dantas / 960 Pontos</h3>
            <p>O método me ajudou bastante a ter uma base boa pra produção dos textos. Consegui organizar melhor os meus conhecimentos e a quantidade de erros gramaticais reduziu bastante. Fiquei muito feliz com a minha evolução e grata pelo aprendizado com o Riemma.</p>
            </div>
          </div>
        </div>
        <div>
          <div css={[columns, depoimentoItens]}>
            <div css={column}>
              <div css={imgItem}>
                <img src={Samira} alt='/' />
              </div>
            </div>
            <div css={borderLine} />
            <div css={column}>
              <h3>Samira Souza / 920 Pontos</h3>
              <p>Eu usei o modelo do Prof. Riemma e segui suas orientações. Falei sobre o racismo velado e, na aula o Prof. Riemma falou sobre a Lei Áurea. Acabei usando porque se encaixava demais. Mantive as argumentações e acrescentei outras coisas e o resultado foi essse notão: 920 pontos!</p>
            </div>
          </div>
        </div>
        <div>
          <div css={[columns, depoimentoItens]}>
            <div css={column}>
              <div css={imgItem}>
                <img src={AnaLaura} alt='/' />
              </div>
            </div>
            <div css={borderLine} />
            <div css={column}>
              <h3>Ana Laura / 960 Pontos</h3>
              <p>O direcionamento que o professor Riemma ofereceu foi incrível e, com certeza, decisivo para uma melhor compreensão acerca da prova de redação, possibilitando, assim, o alcance aos 900 pontos. Me sinto muito feliz e grata por todos os ensinamentos.</p>
            </div>
          </div>
        </div>
        <div>
          <div css={[columns, depoimentoItens]}>
            <div css={column}>
              <div css={imgItem}>
                <img src={Noemi} alt='/' />
              </div>
            </div>
            <div css={borderLine} />
            <div css={column}>
              <h3>Noemi Santos / 940 Pontos</h3>
              <p>Eu utilizei em um parágrafo, um pedaço do modelo de redação por conta do nervosismo que me tomou na hora. Ao decorrer do ano eu assisti a sua aula de redação no YouTube e soube pontuar na hora alguns erros que eu iria errar. Tirei 940 na redação do Enem!</p>
            </div>
          </div>
        </div>
      </ItemsCarousel>
    </div>
  );
};