import * as React from 'react'
import Button from '../components/PreCadastroBtn'
import bgItem from '../images/bg-item.png'
import bgItem2 from '../images/bg-item-2.png'
import bgItemMobile from '../images/bg-item-mobile.png'
import Award from '../images/icons/award.svg'
import AwardBg from '../images/icons/award-bg.png'
import AwardMobile from '../images/icons/awards-mobile.png'
import Setas from '../images/icons/setas.png'
import Setas2 from '../images/icons/setas2.png'
import { Link } from 'gatsby'

import {
  styleComoFunciona,
  container,
  content,
  columns,
  column,
  boxContent,
  desktop,
  desktop2,
  desktop3,
  mobile,
  leftCol,
  rightCol,
  itemComoFunciona,
  item1,
  item2
} from '../components/styles/ComoFunciona.styles'

const ComoFunciona = () => {
  return (
    <div css={[styleComoFunciona, content]}>
      <h2>ENVIE SUA REDAÇÃO, RECEBA CORREÇÕES E ORIENTAÇÕES INCRÍVEIS E CONQUISTE MODELOS EXCLUSIVOS</h2>
      <h3 style={{ marginBottom: 16 }}>Você pode enviar gratuitamente até 4 redações a cada edição do ENEM. A partir da sua nota, você pode destravar até 4 modelos exclusivos e conquistar uma mentoria com o professor Riemma!</h3>
      <h3 style={{ marginBottom: 32 }}><span>Veja como funciona:</span></h3>
      <div css={container} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'column' }}>
        <div
          css={desktop}
          style={{ 
            backgroundImage: `url(${Setas})`,
            backgroundRepeat: 'no-repeat',
            width: 781,
            height: 144,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />
        <div css={columns}>
          <div css={column} style={{ margin: 'auto' }}>
            <div
              css={itemComoFunciona}
              style={{ 
                backgroundImage: `url(${bgItem})`,
                backgroundRepeat: 'no-repeat',
                height: 413,
                width: 269,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div css={boxContent}>
                <img src={Award} />
                <h2>+600<br /> PONTOS NA<br /> REDAÇÃO</h2>
                <p>Destrave o 1º super modelo</p>
              </div>
            </div>
          </div>
          <div css={column} style={{ margin: 'auto' }}>
            <div
              css={itemComoFunciona}
              style={{ 
                backgroundImage: `url(${bgItem})`,
                backgroundRepeat: 'no-repeat',
                height: 413,
                width: 269,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div css={boxContent}>
                <div style={{ display: 'flex' }}>
                  <img src={Award} />
                  <img src={Award} />
                </div>
                <h2>+700<br /> PONTOS NA<br /> REDAÇÃO</h2>
                <p>Destrave o 2º super modelo</p>
              </div>
            </div>
          </div>
          <div css={column} style={{ margin: 'auto' }}>
            <div
              css={itemComoFunciona}
              style={{ 
                backgroundImage: `url(${bgItem})`,
                backgroundRepeat: 'no-repeat',
                height: 413,
                width: 269,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div css={boxContent}>
                <div style={{ display: 'flex' }}>
                  <img src={Award} />
                  <img src={Award} />
                  <img src={Award} />
                </div>
                <h2>+800<br /> PONTOS NA<br /> REDAÇÃO</h2>
                <p>Destrave o 3º super modelo</p>
              </div>
            </div>
          </div>
          <div css={column} style={{ margin: 'auto' }}>
            <div
              css={itemComoFunciona}
              style={{ 
                backgroundImage: `url(${bgItem})`,
                backgroundRepeat: 'no-repeat',
                height: 413,
                width: 269,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div css={boxContent}>
                <div style={{ display: 'flex' }}>
                  <img src={Award} />
                  <img src={Award} />
                  <img src={Award} />
                  <img src={Award} />
                </div>
                <h2>+900<br /> PONTOS NA<br /> REDAÇÃO</h2>
                <p>Destrave o 4º super modelo</p>
              </div>
            </div>
          </div>
        </div>
        <div css={desktop2}>
          <div
            style={{ 
              backgroundImage: `url(${Setas2})`,
              backgroundRepeat: 'no-repeat',
              width: 632,
              height: 256,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              marginTop: '-56px'
            }}
          />
        </div>
        <div css={[columns, desktop3]}>
          <div css={column} style={{ margin: 'auto' }}>
            <div
              css={itemComoFunciona}
              style={{ 
                backgroundImage: `url(${bgItem2})`,
                backgroundRepeat: 'no-repeat',
                width: 743,
                height: 334,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div style={{ display: 'flex' }}>
                <div css={leftCol}>
                  <h2>+960<br /> PONTOS NA REDAÇÃO</h2>
                  <p>Mentoria exclusiva com professor Riemma</p>
                </div>
                <div css={rightCol}>
                  <img src={AwardBg} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div css={[column, mobile]} style={{ margin: 'auto' }}>
          <div
            css={itemComoFunciona}
            style={{ 
              backgroundImage: `url(${bgItemMobile})`,
              backgroundRepeat: 'no-repeat',
              width: 269,
              height: 713,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <div css={boxContent}>
              <div css={item1}>
                <h2>+960<br /> PONTOS NA REDAÇÃO</h2>
                <p>Mentoria exclusiva com professor Riemma</p>
              </div>
              <div css={item2}>
                <img src={AwardMobile} />
              </div>
            </div>
          </div>
        </div>
        <a href='https://app.redacaosolidaria.org' style={{ display: 'flex', justifyContent: 'center', marginTop: 60 }}><Button>FAÇA SEU PRÉ-CADASTRO AGORA</Button></a>
      </div>
    </div>
  )
}

export default ComoFunciona