import * as React from 'react'
import Button from '../components/StartNowBtn'
import Img from '../images/professor-riema.png'
import Instagram from '../images/icons/insta-pop.svg'
import { Link } from 'gatsby'

import {
  column,
  columns,
  container,
  header,
  content,
  desktop,
  mobile
} from '../components/styles/ProfessorReima.styles'

const ProfessorReima = () => {
  return (
    <div css={[header, content]}>
      <div css={container}>
        <div css={columns}>
          <div css={column}>
            <h1 style={{ marginBottom: 18 }}>MUITO PRAZER,<br />SOU O <a href='https://www.instagram.com/rriemma/' target='_blank' rel='noopener noreferrer'><span>@RIEMMA</span></a></h1>
            <p>Sou o Rafael Riemma, <span>fundador do Redação Solidária! Sou professor graduado pela Universidade de Brasília e consultor em língua portuguesa. Especialista em análise sintática pela Universidade de Lisboa e pós-graduado em português jurídico e em administração de empresas.</span></p>
            <p>Bom demais ter você aqui! Minha principal característica como professor é a competência técnica e teórica e a inteligência cognitiva, sem deixar de lado a tolerância, a solidariedade e a escuta sensível. Empatia sempre!</p>
            <p>Tenho a função de compartilhar conhecimento, e como educador me comprometo com a formação integral dos meus alunos e com a sua interação com a família e com a sociedade. Vamos juntos!</p>
            <p style={{ fontSize: 14 }}>
              <span>Fiquem atentos as nossas dicas </span><br />
              <span style={{ display: 'flex', marginTop: 6 }}>
                no instagram
                <a href='https://www.instagram.com/redacao.solidaria/' target='_blank' rel='noopener noreferrer'>
                  <img style={{ marginLeft: 6, width: 170 }} src={Instagram} alt='/' />
                </a>
              </span>
            </p>
            <a  href='https://app.redacaosolidaria.org' css={desktop} style={{ marginTop: 42 }}><Button>COMECE AGORA MESMO</Button></a>
          </div>
          <div css={column}>
            <img src={Img} alt='' />
            <a href='https://app.redacaosolidaria.org' css={mobile} style={{ marginTop: 36 }}><Button>COMECE AGORA MESMO</Button></a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfessorReima