import { css } from "@emotion/react"

export const styleRecursosCompletos = css`
  background-color: #3E6CFF;
  font-family: 'Antonio', sans-serif;
  padding-top: 62px;
  padding-bottom: 62px;
  @media (max-width: 480px) {
    padding: 18px;
    padding-top: 34px;
    padding-bottom: 60px;
  }
`
export const container = css`
  width: 100%;
  margin: 0 auto;
  max-width: 1170px;
`

export const columns = css`
  display: flex;
	flex-flow: row wrap;
	justify-content: center;
	/* margin: 5px 0; */
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
    flex-direction: column;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const column = css`
  flex: 1;
	margin: 2px;
	padding: 10px;
	&:first-child { margin-left: 0; }
	&:last-child { margin-right: 0; }
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
      padding: 18px;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const content = css`
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 55px;
    line-height: 69px;
    display: flex;
    align-items: center;
    letter-spacing: -0.05em;
    font-weight: normal;
    margin: 0;
    color: #FFFAA9;
    margin-bottom: 18px;
  }
  p {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #FFFAA9;
    max-width: 764px;
    width: 100%;
    margin: 0;
    margin-bottom: 32px;
    span {
      font-weight: bold;
    }
  }
  @media (max-width: 480px) {
    h2 {
      font-size: 45px;
      line-height: 54px;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 0px;
    }
  }
`

export const boxContent = css`
  font-family: 'Poppins', sans-serif;
  max-width: 268px;
  width: 100%;
  margin: 0 auto;
  h3 {
    font-weight: bold;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #FFFAA9;
    margin: 0px;
    margin-bottom: 8px;
  }
  h4 {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #1E1E1E;
    margin: 0px;
  }
`

export const depoimentoItens = css`
  margin: auto;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  h3 {
    font-weight: bold;
    font-size: 22px;
    line-height: 33px;
    color: #FFFAA9;
    width: 100%;
    margin-left: 60px;
    text-align: left;
    padding-bottom: 16px;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #EAEAEA;
    text-align: left;
    margin-bottom: 0px;
    max-width: 360px;
    width: 100%;
    margin-left: 60px;
  }
  @media (max-width: 480px) {
    h3 {
      margin-left: 0px;
      text-align: center;
      max-width: 180px;
      margin: auto;
    }
    p {
      margin-left: 0px;
      text-align: center;
    }
  }
`

export const borderLine = css`
  border: 2px solid #1E1E1E;
  height: 240px;
  @media (max-width: 480px) {
    display: none;
  }
`

export const arrowPosition = css`
  @media (max-width: 480px) {
    margin-top: 580px;
    padding-top: 20px;
    margin-bottom: 20px;
  }
`

export const btn = css`
  display: flex;
  justify-content: center;
  margin-top: 42px;
  @media (max-width: 480px) {
    margin-top: 92px;
  }
`

export const arrowR = css`
  @media (max-width: 480px) {
    margin-right: 20px;
  }
`

export const arrowL = css`
  @media (max-width: 480px) {
    margin-left: 20px;
  }
`

export const mt52 = css`
  margin-top: 42px;
  @media (max-width: 480px) {
    margin-top: 12px;
  }
`

export const imgItem = css`
  text-align: end;
  margin-right: 60px;
  @media (max-width: 480px) {
    text-align: center;
    margin-right: 0px;
  }
`