import { css } from "@emotion/react"

export const header = css`
  background-color: #FFFAA9;
  font-family: 'Antonio', sans-serif;
  padding-bottom: 100px;
  @media (max-width: 480px) {
    padding-bottom: 54px;
  }
`
export const container = css`
  width: 100%;
  margin: 0 auto;
  max-width: 1170px;
`

export const columns = css`
  display: flex;
	flex-flow: row wrap;
	justify-content: center;
	/* margin: 5px 0; */
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
    flex-direction: column;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const column = css`
  flex: 1;
	margin: 2px;
	padding: 10px;
	&:first-child { margin-left: 0; }
	&:last-child { margin-right: 0; }
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
      padding: 18px;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const content = css`
  h1 {
    font-weight: normal;
    font-size: 72px;
    line-height: 93px;
    display: flex;
    align-items: center;
    letter-spacing: -0.05em;
    color: #1E1E1E;
    max-width: 428px;
    width: 100%;
    margin: 0;
    display: block;
    span {
      color: #3E6CFF;
    }
    span:hover {
      text-decoration: underline;
    }
  }
  h2 {
    font-size: 55px;
    line-height: 79px;
    display: flex;
    align-items: center;
    letter-spacing: -0.05em;
    font-weight: normal;
    margin: 0;
  }
  p {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #1E1E1E;
    max-width: 428px;
    width: 100%;
    margin: 0;
    span {
      font-weight: bold;
    }
    :not(:last-child) {
      margin-bottom: 18px;
    }
  }
  @media (max-width: 480px) {
    h1 {
      font-size: 50px;
      line-height: 65px;
    }
    h2 {
      font-size: 45px;
      line-height: 54px;
    }
    p {
      a {
        width: 40%;
      }
    }
    img {
      width: 100%;
    }
  }
`

export const contentPosition = css`
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const desktop = css`
  display: flex;
  @media (max-width: 480px) {
    display: none;
  }
`

export const mobile = css`
  justify-content: center;
  align-items: center;
  display: flex;
  @media (min-width: 480px) {
    display: none;
  }
`