import * as React from 'react'
import Ebook from '../images/ebook.png'
import Button from '../components/StartNowBtn'
import PDF from '../documents/ebook-red-solidaria.pdf'

import {
  container,
  BaixarEbookStyle,
  content,
  ebookStyle
} from '../components/styles/Header.styles'

const BaixarEbook = () => {
  return (
    <div css={[BaixarEbookStyle, content]}>
      <div css={container}>
        <div css={ebookStyle}>
          <img src={Ebook} alt='' />
          <h2 style={{ marginBottom: 16 }}><span style={{ marginRight: 10 }}>CHEGOU!</span> O E-BOOK QUE PODE TE LEVAR À REDAÇÃO <span style={{ marginLeft: 10 }}>NOTA 1000.</span></h2>
          <a href={PDF}><Button>BAIXE GRATUITAMENTE</Button></a>
        </div>
      </div>
    </div>
  )
}

export default BaixarEbook