import * as React from 'react'
import Button from '../components/StartNowBtnGray'
import { Link } from 'gatsby'

import { 
  ctaContent,
  ctaBox
} from '../components/styles/Cta.styles'

const CtaSection = () => {
  return (
    <div css={ctaContent}>
      <div css={ctaBox}>
        <h2>POR QUE ESTUDAR  REDAÇÃO?</h2>
        <p>Ela representa até <span>50% da nota final dos vestibulares</span>. Com a nossa orientação, com esforço na medida certa, você aumentará sua nota de forma exponencial.</p>
        <p><span>Melhore seu desempenho e fique ainda mais perto dos seus sonhos.</span></p>
        <a href='https://app.redacaosolidaria.org'><Button>COMECE AGORA MESMO</Button></a>
      </div>
    </div>
  )
}

export default CtaSection