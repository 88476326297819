import { css } from "@emotion/react"
import BgDesktop from '../../images/bg-form-2.png'
import BgMobile from '../../images/form-mobile.png'

export const styleDicas = css`
  background-color: #EAEAEA;
  font-family: 'Antonio', sans-serif;
  padding-top: 62px;
  padding-bottom: 52px;
`
export const container = css`
  width: 100%;
  margin: 0 auto;
  max-width: 1240px;
`

export const columns = css`
  display: flex;
	flex-flow: row wrap;
	justify-content: center;
	/* margin: 5px 0; */
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
    flex-direction: column;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const column = css`
  flex: 1;
	margin: 2px;
	padding: 10px;
	&:first-child { margin-left: 0; }
	&:last-child { margin-right: 0; }
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const content = css`
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 72px;
    line-height: 89px;
    display: flex;
    align-items: center;
    letter-spacing: -0.05em;
    font-weight: normal;
    margin: 0;
    color: #1E1E1E;
    margin-bottom: 12px;
  }
  p {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #1E1E1E;
    max-width: 764px;
    width: 100%;
    margin: 0;
    margin-bottom: 32px;
    span {
      font-weight: bold;
    }
  }
  @media (max-width: 480px) {
    h2 {
      font-size: 45px;
      line-height: 54px;
      margin-bottom: 20px;
      max-width: 300px;
      width: 100%;
    }
    p {
      margin-bottom: 0px;
    }
  }
`

export const header = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 50px; */
`

export const label = css`
  width: 100%;
  margin-bottom: 70px;
  input {
    background: #EAEAEA;
    border-radius: 10px;
    width: 100%;
    height: 48px;
    border-radius: 0px;
    border: 0;
    padding-left: 24px;
    font-weight: bold;
    font-size: 22px;
    line-height: 33px;
    color: #1E1E1E;
    border-bottom: 1px solid #1E1E1E;
    font-family: 'Poppins', sans-serif;
  }
  textarea {
    width: 100%;
    background: #EAEAEA;
    border-bottom: 1px solid #1E1E1E !important;
    border-radius: 0;
    border: 0;
    font-weight: bold;
    font-size: 22px;
    line-height: 43px;
    color: #1E1E1E;
    font-family: 'Poppins', sans-serif;
    padding-top: 24px;
    padding-left: 20px;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    input {
      width: 80%;
    }
    textarea {
      width: 80%;
    }
  }
`

export const formulario = css`
  max-width: 560px;
  width: 100%;
  margin: auto;
  padding-top: 60px;
  margin-top: -150px;
  @media (max-width: 480px) {
    margin-top: -35px;
  }
`

export const desktop = css`
  @media (max-width: 480px) {
    display: none;
  }
`

export const mobile = css`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (min-width: 480px) {
    display: none;
  }
`

export const imgFormDesktop = css`
  @media (max-width: 480px) {
    margin-top: -140px;
    background: url(${BgMobile}) no-repeat;
    background-repeat: no-repeat;
    height: 711px;
    width: 350px;
    background-size: cover;
    background-position: 'center';
    margin: auto;
  }
  @media (min-width: 481px) {
    margin-top: -140px;
    background: url(${BgDesktop}) no-repeat;
    background-repeat: no-repeat;
    height: 664px;
    width: 719px;
    background-size: cover;
    background-position: 'center';
    margin: auto;
  }
`
