import { css } from "@emotion/react"

export const styleComoFunciona = css`
  background-color: #FFFAA9;
  font-family: 'Antonio', sans-serif;
  padding-top: 62px;
  @media (max-width: 480px) {
    padding: 18px;
  }
`
export const container = css`
  width: 100%;
  margin: 0 auto;
  max-width: 1240px;
`

export const columns = css`
  display: flex;
	flex-flow: row wrap;
	justify-content: center;
	/* margin: 5px 0; */
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
    flex-direction: column;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const column = css`
  flex: 1;
	margin: 2px;
	padding: 10px;
	&:first-child { margin-left: 0; }
	&:last-child { margin-right: 0; }
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const content = css`
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 55px;
    line-height: 79px;
    display: flex;
    align-items: center;
    letter-spacing: -0.05em;
    font-weight: normal;
    margin: 0;
    color: #1E1E1E;
    margin-bottom: 18px;
    max-width: 930px;
  }
  h3 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #1E1E1E;
    max-width: 765px;
    span {
      font-weight: bold;
    }
  }
  p {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #FFFAA9;
    max-width: 764px;
    width: 100%;
    margin: 0;
    margin-bottom: 32px;
    span {
      font-weight: bold;
    }
  }
  @media (max-width: 480px) {
    h2 {
      font-size: 45px;
      line-height: 54px;
      margin-bottom: 32px;
      margin-top: 32px;
    }
    p {
      margin-bottom: 0px;
    }
  }
`

export const boxContent = css`
  position: absolute;
  width: 250px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 55px;
    line-height: 79px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.05em;
    color: #3E6CFF;
    margin: 0;
    margin-bottom: 12px;
    justify-content: center;
  }
  h3 {
    font-weight: bold;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #1E1E1E;
    margin: 0;
    margin-bottom: 12px;
    font-family: 'Poppins', sans-serif;
    margin-left: 16px;
  }
  p {
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: #1E1E1E;
    margin: 0;
    max-width: 222px;
    font-family: 'Poppins', sans-serif;
    margin-left: 16px;
  }
  img {
    margin-top: 22px;
    margin-bottom: 12px;
  }
`

export const desktop = css`
  margin-bottom: -80px;
  @media (max-width: 480px) {
    display: none;
  }
`

export const desktop2 = css`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 130px;
  margin-bottom: -120px;
  @media (max-width: 480px) {
    display: none;
  }
`

export const desktop3 = css`
  @media (max-width: 480px) {
    display: none;
  }
`

export const leftCol = css`
  background: #1E1E1E;
  border: 2px solid #1E1E1E;
  box-sizing: border-box;
  height: 313px;
  width: 409px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    font-size: 55px;
    line-height: 79px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.05em;
    font-feature-settings: 'kern' off;
    color: #FFFAA9;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFAA9;
    max-width: 222px;
  }
`

export const rightCol = css`
  width: 314px;
  align-items: center;
  display: flex;
  justify-content: center;
`

export const itemComoFunciona = css`
  transition: all 0.25s linear 0s;
  :hover {
    transform: translate(0px, -4px);
    box-shadow: rgb(0 0 0 / 12%) -4px 10px 20px 0px;
  }
`

export const item1 = css`
  margin-top: 18px;
  background: #1E1E1E;
  width: 250px;
  height: 342px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    font-size: 55px;
    line-height: 79px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.05em;
    font-feature-settings: 'kern' off;
    color: #FFFAA9;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFAA9;
    max-width: 222px;
  }
`

export const mobile = css`
  @media (min-width: 480px) {
    display: none;
  }
`