import React, { useState } from 'react'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import BgVideo from '../images/videos/explicacao-do-modelo-de-redacao.png'
import Play from '../images/icons/play.svg'

import {
  imgPlay,
  videoImg,
  carouselContent
} from '../components/styles/Dicas.styles'

const App = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <div>
      <div css={carouselContent} style={{ borderRight: '3px solid #1E1E1E' }}>
        <div onClick={onOpenModal}>
          <div
          css={videoImg}
          style={{ 
            backgroundImage: `url(${BgVideo})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
          onClick={onOpenModal}
        >
          <div css={imgPlay} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <img src={Play} style={{ width: 108 }} />
          </div>
        </div>
        </div>
        <h3>Explicação do modelo<br /> de redação </h3>
        {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p> */}
      </div>
      <Modal open={open} onClose={onCloseModal} center>
        <div>
          <iframe style={{ top: 0, left:0, width: 800, height: 600 }}  src="https://player.vimeo.com/video/563320381?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="5 dicas para melhorar o seu repert&amp;oacute;rio - Rafael Reimma"/>
        </div>
      </Modal>
    </div>
  )
}

export default App